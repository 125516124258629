import axios from 'axios'
import {TokenStorage} from '../storage'
const ApiService = {

    init(baseURL = null) {
        if (baseURL) {
            axios.defaults.baseURL = baseURL;
        } else if (process.env.MIX_PUSHER_APP_API) {
            axios.defaults.baseURL = process.env.MIX_PUSHER_APP_API;
        }

        this.setHeader();

        if (TokenStorage.getToken()) {
            this.setAuthHeader();
        }
    },

    setHeader() {
        axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    },

    setAuthHeader() {
        axios.defaults.headers.common["Authorization"] = `${TokenStorage.getToken()}`;
    },

    removeAuthHeader() {
        axios.defaults.headers.common["Authorization"] = null;
    },

};

export default ApiService;
