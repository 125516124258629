import Vue from "vue";
export default {
  SET_BRANDS(state, data) {
    Vue.set(state, "galleryImages", data);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  ADD_BRAND(state, data) {
    state.galleryImages.push(data);
  },
  DELETE_BRAND(state, galleryId) {
    const index = state.galleryImages.findIndex((x) => x.id === galleryId);
    state.galleryImages.splice(index, 1);
  },
  UPDATE_BRAND(state, data) {
    const index = state.galleryImages.findIndex((x) => x.id === data.id);
    Vue.set(state.galleryImages, index, data);
  },
};
