import { mapActions } from "vuex";
import locales from "./locales";
// import $_color from "@Global/utils/module-color";
import Vue from 'vue';

const modal = {
        ...mapActions("modal", [
            "__openModal",
            "$_closeModal"
        ]),
        $_openModal(name, data = {}) {
            this.__openModal({...data, ...{name}})
        },
};

const methods = {
    $_staticPath(path) {
        return 'https://cdn.lilianamodels.com/liliana/static-files/' + path;
    }
};

Vue.mixin({
    methods: {...modal, ...locales, ...methods}
});

