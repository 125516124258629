import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
import ClientRepository from "@/abstraction/repository/clientRepository";
import Vue from "vue";
let clientRepository = new ClientRepository();
let jobOfferRepository = new JobOfferRepository();
export default {
  async loadJobOffers({ state, commit }) {
    try {
      commit("SET_LOADING_OFFER", true);
      let data = { pagination: state.paginationJobOffers, filters: state.jobOfferFilter };
      const resource = await jobOfferRepository.indexJobOffers(data);
      commit("SET_JOB_OFFERS", resource.data);
      commit("SET_PAGINATION_JOB_OFFERS", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_OFFER", false);
    }
  },
  async loadResponses({ state, commit },offerId) {
    try {
      commit("SET_LOADING_RESPONSE", true);
      let data = { pagination: state.paginationResponse, filters: state.responseFilter };
      const resource = await jobOfferRepository.indexResponse(offerId,data);
      commit("SET_RESPONSES", resource.data);
      commit("SET_PAGINATION_RESPONSE", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_RESPONSE", false);
    }
  },
  async loadResponsesWithUniqueId({ state, commit },uniqueId) {
    try {
      commit("SET_LOADING_RESPONSE", true);
      let data = { pagination: state.paginationResponse, filters: state.responseFilter };
      const resource = await jobOfferRepository.indexResponsesWithUniqueId(uniqueId,data);
      if(typeof resource === "number") {return resource;}
      commit("SET_RESPONSES", resource);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_RESPONSE", false);
    }
  },
  async storeJobOffer({ commit }, data) {
    const resource = await jobOfferRepository.storeJobOffer(data);
    commit("ADD_JOB_OFFER", resource);
    if (resource) return true;
  },
  async storeResponse({ commit }, data) {
    try {
      const resource = await jobOfferRepository.storeResponse(data);
      commit("ADD_RESPONSE", resource);
      if (resource) return true;
    } catch (error) {
      return false;
    }
  },
  async clearResponseSelected({ commit }) {
      commit("CLEAR_RESPONSE_SELECTED");
  },
  async deleteJobOffer({ commit }, offerId) {
    await jobOfferRepository.destroyJobOffer(offerId);
    commit("DELETE_JOB_OFFER", offerId);
  },
  async updateJobOffer({ commit }, data) {
    const resource = await jobOfferRepository.updateJobOffer(data.id, data);
    commit("UPDATE_JOB_OFFER", resource);
  },
  
  async changeStatusManager({commit},data){
    const response = await jobOfferRepository.changeStatusManager(data.id,data);
    commit("UPDATE_JOB_OFFER", response);
  },
  
  async changeStatusResponse({commit},data){
    const response = await jobOfferRepository.changeStatusJobOfferResponse(data.id,data);
    commit("UPDATE_RESPONSE", response);
  },

  async changeStatusResponseClient({commit},data){
    const response = await jobOfferRepository.changeStatusClient(data.id,data);
    commit("MERGE_RESPONSE", response);
  },
  
  removeFromNotSelected({commit},response){
    commit("REMOVE_RESPONSE_FROM_NOT_SELECTED", response.id)
  },

  removeFromSelected({state},data){
    if(state.allResponseSelected){
      state.responseNotSelected.push(data)
    }
  },
  
  async loadModelSelection({ state, commit }) {
    try {
      let data = { pagination: state.modelSelectionPagination, filters: state.jobOfferFilter };
      const resource = await clientRepository.indexModelSelection(data);
      commit("SET_MODEL_SELECTION", resource.data);
      commit("SET_PAGINATION_MODEL_SELECTION", { pagination: resource.modelSelectionPagination });
    } catch (e) {
      return e;
    }
  },
  
  async deleteResponse({ commit }, responseId) {
    await jobOfferRepository.destroyJobOfferResponse(responseId);
    commit("DELETE_RESPONSE", responseId);
    commit("DELETE_FROM_SELECTED", responseId);
  },
  async changeStatusModelSelection({ commit },data) {
    try {
      const resource = await clientRepository.changeStatusModelSelection(data);
      commit("UPDATE_MODEL_SELECTION", resource);
    } catch (e) {
      return e;
    }
  },
  async loadResponseStatuses({ commit, state }) { 
    if (state.responseStatuses.length) return;
    const resource = await jobOfferRepository.indexStatusOffer();
    commit("SET_RESPONSE_STATUSES", resource);
  },
  async sendLinkToClient({ state }, data) {
    data.modelSelectedType = state.responseSelected.length ? "selected" : "allAdminAccepted";
    data.jobOfferResponses = state.responseSelected.map(({id})=>{return id})
    await jobOfferRepository.storeLinkManager(data);
  },
  addToSelected({state},data){
    if(state.allResponseSelected){
      const index = state.responseNotSelected.findIndex((x) => x.id === data.id)
      Vue.delete(state.responseNotSelected, index);
    }
  },
  toggleSelectAll ({state}){
    if(state.allResponseSelected){
      state.responseSelected = []
      state.responseNotSelected = []
    }
    state.allResponseSelected = !state.allResponseSelected;
  },
  async sendEmail({state}, {job_offer_id,response}) {
    if(state.isFiltered && state.allResponseSelected){
      response.filters = state.responseFilter
      response.expect = state.responseNotSelected.map(({user_id})=>(user_id))
      response.ids = ["all"]
    }else if(state.allResponseSelected){
      response.expect = state.responseNotSelected.map(({user_id})=>(user_id))
      response.ids = ["all"]
    }else if(state.responseSelected.length){
      response.ids = state.responseSelected.map(({user_id})=>(user_id))
    }else if(response.user_id){
      response.ids = [response.user_id]
    } 
    return await jobOfferRepository.sendEmailToResponses(job_offer_id,response);
  },
  setIsFiltered({state},data){
    state.isFiltered = data;
  },
  async changeStatusSelected({ commit, state }, data) {
    try {
      if (state.isFilteredResponse && state.allResponseSelected) {
        data.filters = state.responseFilter;
        data.expect = state.responseNotSelected.map(({ id }) => id);
        data.ids = ["all"];
      } else if (state.allResponseSelected) {
        data.expect = state.responseNotSelected.map(({ id }) => id);
        data.ids = ["all"];
      } else if (state.responseSelected.length) {
        data.ids = state.responseSelected.map(({ id }) => id);
      } else if (data.id) {
        data.ids = [data.id];
      } else {
        data.ids = ["all"];
      }
  
      await jobOfferRepository.changeStatusSelected(data)

      commit("CHANGE_STATUS_SELECTED_RESPONSE", data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },
};
