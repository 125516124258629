import { urlGenerator } from "@/utils/urlGenerator";

const urls = {
  indexUsers: "users",
  indexClient: "clients",
  storeClient: "clients",
  createClient: "clients",
  updateClient: "clients/:client",
  updateProfile: "update-profile",
  destroyClient: "clients/:client",
  showClient: "clients/:client",
  indexModel: "models",
  indexMultipleModel: "get-multiple-models",
  verifyModel: "models/:model/verify",
  appendUpdateModel: "models/:model/verifyUpdate",
  acceptUpdateModelAdmin: "models/:model/acceptVerifyUpdate",
  rejectUpdateModelAdmin: "models/:model/rejectVerifyUpdate",
  updateVisibilityModel: "models/:model/visibility",
  updateModelAdmin: "users/:model/models",
  destroyUser: "users/:user",
  updateUser: "users/:user",
  showUser: "users/:user",
  updateModelUser: "updateModel",
  indexBrand: "brands",
  storeBrand: "brands",
  updateBrand: "brands/:brand",
  destroyBrand: "brands/:brand",
  showBrand: "brands/:brand",
  indexManager: "managers",
  storeManager: "managers",
  updateManager: "managers/:manager",
  destroyManager: "managers/:manager",
  showManager: "managers/:manager",
  indexRequest: "requests",
  storeRequest: "requests",
  multiStoreRequest: "multi-requests",
  updateRequest: "requests/:request",
  destroyRequest: "requests/:request",
  showRequest: "requests/:request",
  indexHairColor: "hair-colors",
  indexEyeColor: "eye-colors",
  indexGender: "genders",
  indexEthnic: "ethnics",
  indexAccountClassification: "account-classifications",
  indexCountry: "countries",
  indexStatus: "statuses",
  indexModelType: "model-types",
  indexLanguage: "languages",
  indexLanguageLevel: "language-levels",
  storeFile: "files",
  destroyFile: "files/:file",
  indexMyInfo: "my-info",
  indexProject: "projects",
  storeProject: "projects",
  destroyProject: "projects/:project",
  updateProject: "projects/:project",
  indexGallery: "gallery",
  storeGallery: "gallery",
  destroyGallery: "gallery/:gallery",
  updateRequestStatus: "requests/:request/change-status/:status",
  indexCity: "cities",
  indexContact: "contacts",
  verifyImage : "files/:file/verify",
  unverifyImage : "files/:file/un-verify",
  indexSpecialView:"special-views",
  updateSpecialViews:"users/:user/special-views",
  pdfVerify : "files/:media/pdf-verify",
  pdfUnVerify : "files/:media/pdf-un-verify",
  sendEmailToModels : "models/email",
  sendEmailToClients : "clients/email",
  sendEmailToUsers : "users/email",
  forgetPassword : "forgot-password",
  forgetPasswordVerify : "forgot-password-verify",
  changeRole : "user/:user/change-role",
  dashboardNotifications : "dashboard/manager/not-seen",
  changeStatusManager : "job-offers/:jobOffer/change-status",
  changeStatusResponseClient : "job-offer-response/:jobOffer/change-client-status",
  indexJobOffers : "job-offers",
  storeJobOffer : "job-offers",
  storeLinkManager : "job-offer-link-clients",
  loginWithJobOfferLink : "job-offer-link-client/:uniqueId/login",
  indexResponsesWithUniqueId : "job-offer-link-client/:uniqueId/responses",
  showJobOfferWithUniqueId : "job-offer-link-client/:uniqueId/job-offer",
  jobOfferLink : "job-offer-link-clients",
  storeResponse : "job-offer-responses",
  showJobOffer : "job-offers/:jobOffer",
  destroyJobOffer : "job-offers/:jobOffer",
  updateJobOffer : "job-offers/:jobOffer",
  changeStatusJobOffer : "job-offers/:jobOffer/change-status",
  indexJobOfferResponse : "job-offer/:jobOffer/responses",
  showJobOfferResponse : "job-offer-responses/:jobOffer",
  showResponseWithUniqueId : "get-job-offer/:uniqueId",
  changeStatusJobOfferResponse : "job-offer-responses/:jobOffer/change-status",
  destroyJobOfferResponse : "job-offer-responses/:jobOffer",
  storeJobOfferLink: "job-offer-link-clients",
  hasJobOfferLink: "job-offer-link-client/:uniqueId/isset",
  indexResponseStatuses : "job-offer-response-statuses",
  indexModelSelection: "job-offer-link-client/:jobOffer/responses",
  changeStatusModelSelection: "job-offer-link-client/:jobOffer/responses",
  sendEmailToResponses : "jobOffer/:jobOffer/email",
  emailSender : "email/:batch/send",
  replyEmail : "contact-us/:contact/reply-email",
  updateAttrValues : "users/:user/attribute",
  // ** auth **//
  login: "login",
  logout: "logout",
  register: "register",
  registerResendCode: "register-resend-code",
  verifyCode : "verify-code",
  forgetPasswordResendCode: "forgot-password-resend-code",
  resetPassword: "reset-password",
  // ** job offer **//
  changeStatusSelected : "job-offers/:jobOffer/job-offer-responses/change-status",
  // ** model **//
  exportExcel : "models/export",
  destroyMultipleModels : "models",
  getLinkDownloadImages : "models/:user/get-link-download-images",
  // ** email template **//
  indexEmailTemplate : "email-templates",
  storeEmailTemplate : "email-templates",
  destroyEmailTemplate : "email-templates/:emailTemplate",
  updateEmailTemplate :  "email-templates/:emailTemplate",

  // ** bookmark **//
  indexBookmark : "my-bookmark",
  syncBookmark : "sync-bookmark/:model",

  // ** attribute **//
  syncAttribute : "sync-attributes",
  indexAttribute : "attributes",

  // ** Portfolio **//
  indexPortfolio : "portfolios",
  storePortfolio : "portfolios",
  showPortfolio : "portfolios/:portfolio",
  updatePortfolio : "portfolios/:portfolio",
  destroyPortfolio : "portfolios/:portfolio",
  verifyPortfolio : "portfolios/:portfolio/verify",
  // ** Blog **//
  indexBlog : "blogs",
  storeBlog : "blogs",
  showBlog : "blogs/:blog",
  updateBlog : "blogs/:blog",
  destroyBlog : "blogs/:blog",
  verifyBlog : "blogs/:blog/verify",
};

export default urlGenerator(urls);
