import EyeColorRepository from "@/abstraction/repository/eyeColorRepository";
let eyeColorRepository = new EyeColorRepository();
export default {
  async loadEyeColors({ commit, state }) {
    if (state.eyeColors.length) return;

    const resource = await eyeColorRepository.index();
    commit("SET_EYE_COLORS", resource.data);
  },
};
