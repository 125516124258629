export default {
  getJobOffers: (state) => state.jobOffers,
  getPaginationJobOffer: (state) => state.paginationJobOffers,
  getPaginationResponse: (state) => state.paginationResponse,
  getResponse: (state) => state.responses,
  getModelSelection: (state) => state.modelSelection,
  getResponseStatuses: (state) => state.responseStatuses,
  getResponseSelected: state => {
    if (state.allResponseSelected) {
      return state.responses.filter((response) => {
        return state.responseNotSelected.findIndex(x => x.id === response.id) < 0;
      })
    } else {
      return state.responseSelected
    }
  },
  getResponseNotSelected: state => state.responseNotSelected,
  getAllResponseSelected: state => state.allResponseSelected,
  getResponseFilters: state => state.responseFilter,
  getLoadingResponse: state => state.loadingResponse,
  getLoadingOffer: state => state.loadingOffer,
  getFiltersOffer: state => state.jobOfferFilter,
  getFiltersResponse: state => state.responseFilter,
  isFilteredResponse: state => state.isFilteredResponse,
  getFiltersModelSelection: state => state.modelSelectionFilter
};
