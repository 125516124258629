import Vue from "vue";
const REJECTED_RESPONSE = {
  id: 4,
  name: "Rejected By Admin",
  name_jpn: "管理者によって拒否されました",
  style: { color: "red", bgColor: "bgLightPink" },
  bgColor: "bgLightPink",
  color: "red",
  verification_permission: "manager",
  verify: 0,
};
const ACCEPTED_RESPONSE = {
  id: 3,
  name: "Accepted By Admin",
  name_jpn: "管理者が承認",
  style: { color: "cyan", bgColor: "bgCyan" },
  bgColor: "bgCyan",
  color: "cyan",
  verification_permission: "manager",
  verify: 1,
};
export default {
  //-----------------------------------------------------
  //                        SET
  //-----------------------------------------------------
  SET_JOB_OFFERS(state, data) {
    Vue.set(state, "jobOffers", data);
  },
  SET_RESPONSES(state, data) {
    Vue.set(state, "responses", data);
  },
  SET_MODEL_SELECTION(state, data) {
    Vue.set(state, "modelSelection", data);
  },
  SET_PAGINATION_JOB_OFFERS(state, paginationJobOffers) {
    paginationJobOffers = {
      ...state.paginationJobOffers,
      ...paginationJobOffers,
    };
    Vue.set(state, "paginationJobOffers", paginationJobOffers);
  },
  SET_PAGINATION_RESPONSE(state, paginationResponse) {
    paginationResponse = { ...state.paginationResponse, ...paginationResponse };
    Vue.set(state, "paginationResponse", paginationResponse);
  },
  SET_PAGINATION_MODEL_SELECTION(state, modelSelectionPagination) {
    modelSelectionPagination = {
      ...state.paginationResponse,
      ...modelSelectionPagination,
    };
    Vue.set(state, "modelSelectionPagination", modelSelectionPagination);
  },
  SET_FILTER_JOB_OFFER(state, data) {
    Vue.set(state, "jobOfferFilter", data);
    state.paginationJobOffers.page = 1;
  },
  SET_RESPONSE_FILTER(state, data) {
    Vue.set(state, "responseFilter", data);
    state.paginationResponse.page = 1;
  },
  SET_RESPONSE_STATUSES(state, data) {
    Vue.set(state, "responseStatuses", data);
  },
  SET_FILTER_MODEL_SELECTION(state, data) {
    Vue.set(state, "modelSelectionFilter", data);
  },
  //-----------------------------------------------------
  //                        ADD
  //-----------------------------------------------------
  ADD_JOB_OFFER(state, data) {
    state.jobOffers.unshift(data);
  },
  ADD_RESPONSE(state, data) {
    state.responses.push(data);
  },
  //-----------------------------------------------------
  //                        DELETE
  //-----------------------------------------------------
  DELETE_JOB_OFFER(state, jobOfferId) {
    const index = state.jobOffers.findIndex((x) => x.id === jobOfferId);
    Vue.delete(state.jobOffers, index);
  },
  DELETE_RESPONSE(state, responseId) {
    const index = state.responses.findIndex((x) => x.id === responseId);
    Vue.delete(state.responses, index);
  },
  //-----------------------------------------------------
  //                        REMOVE
  //-----------------------------------------------------
  REMOVE_RESPONSE_SELECTED(state, responseId) {
    let index = state.responseSelected.findIndex((x) => x.id === responseId);
    Vue.delete(state.responseSelected, index);
  },
  REMOVE_RESPONSE_FROM_NOT_SELECTED(state, responseId) {
    let index = state.responseNotSelected.findIndex((x) => x.id === responseId);
    Vue.delete(state.responseNotSelected, index);
  },
  //-----------------------------------------------------
  //                        UPDATE
  //-----------------------------------------------------
  CHANGE_STATUS_SELECTED_RESPONSE(state, data) {
    console.log(data, state);
    if (data.ids[0] === "all") {
      state.responses.map((response) => {
        if (!data.expect.includes(response.id)) {
          changeResponseStatusById(response.id)
        }
      });
    } else{
      data.ids.map(id=>changeResponseStatusById(id))
    }

    function changeResponseStatusById(id){
      const index = state.responses.findIndex((x) => x.id === id);
          state.responses[index].job_offer_response_status_id = data.status_id;
          const newStatus = data.status_id === 3 ? ACCEPTED_RESPONSE : REJECTED_RESPONSE;
          state.responses[index].status = newStatus;
    }
  },
  UPDATE_JOB_OFFER(state, data) {
    const index = state.jobOffers.findIndex((x) => x.id === data.id);
    Vue.set(state.jobOffers, index, data);
  },
  UPDATE_RESPONSE(state, data) {
    const index = state.responses.findIndex((x) => x.id === data.id);
    Vue.set(state.responses, index, data);
  },
  MERGE_RESPONSE(state, data) {
    const index = state.responses.findIndex((x) => x.id === data.id);
    const updatedVal = { ...state.responses[index], ...data };
    Vue.set(state.responses, index, updatedVal);
  },
  UPDATE_MODEL_SELECTION(state, data) {
    const index = state.modelSelection.findIndex((x) => x.id === data.id);
    Vue.set(state.modelSelection, index, data);
  },
  SET_LOADING_OFFER(state, status) {
    Vue.set(state, "loadingOffer", status);
  },
  SET_LOADING_RESPONSE(state, status) {
    Vue.set(state, "loadingResponse", status);
  },
  SET_IS_FILTERED_RESPONSE(state, status) {
    Vue.set(state, "isFilteredResponse", status);
  },
  CLEAR_RESPONSE_SELECTED(state) {
    state.responseSelected = [];
    state.allResponseSelected = false;
    state.responseNotSelected = [];
  },
  DELETE_FROM_SELECTED(state, responseId) {
    if(state.allResponseSelected){
      let index = state.responseNotSelected.findIndex(x => x.id === responseId);
      Vue.delete(state.responseNotSelected,index);
    }else if(state.responseSelected.length){
      let index = state.responseSelected.findIndex(x => x.id === responseId);
      Vue.delete(state.responseSelected,index);
    }
  },
  DESTROY_HISTORY_OFFERS(state){
    state.jobOfferFilter = {}
    state.paginationJobOffers = {itemsPerPage: 15}
    state.isFiltered = false;
  },
  DESTROY_HISTORY_RESPONSE(state){
    state.responseFilter = {}
    state.paginationResponse = {itemsPerPage: 15}
    state.isFilteredResponse = false;
  },
};
