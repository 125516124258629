import Pages from '../locales/pages';
import SpecialLocales from '../locales/special';
import { get } from 'lodash';

export default {
    $_t(key, parameters) {
        let locale = this.$_getLocale();
        if (Pages[locale][this.$route.name]) {
            let translated = get(Pages[locale][this.$route.name], key);

            if (parameters) {
                for (const index in parameters) {
                    translated = translated.replace('{' + index + '}', parameters[index])
                }
            }

            return translated;
        }
    },
    $_tc(key, index) {
        let locale = this.$_getLocale();
        if (Pages[locale][this.$route.name]) {
            let translated = get(Pages[locale][this.$route.name], key);

            if (index) {
                translated = translated.split('|');
                return translated[index - 1];
            }
            return translated;
        }
    },
    $_trans(key, parameters) {
        let locale = this.$_getLocale();
        if (SpecialLocales[locale][key.split('.')[0]]) {
            let translated = get(SpecialLocales[locale], key);

            if (parameters) {
                for (const index in parameters) {
                    translated = translated.replace('{' + index + '}', parameters[index])
                }
            }

            return translated;
        }
    },
    $_getLocale() {
        let route = this.$route ? this.$route.path : location.pathname;
        return route.split('/')[1] === 'en' ? 'en' : 'jp';
    },
    $_getlocaleParam() {
        return this.$_getLocale() === 'en' ? 'en' : null;
    },
    $_changeLocalePage() {
        let locale = this.$_getLocale();
        if (locale === 'en') {
            this.$router.push({ name: this.$route.name });
        } else {
            this.$router.push({ name: this.$route.name, params: { locale: 'en' } });
        }
    },
    $_changeModelProperty(model, value) {
        let locale = this.$_getLocale();
        if (locale === 'jp') {
            if (model[`${value}_jpn`]) {
                value = `${value}_jpn`;
            }
            return model[value]
        }
        return model[value];
    },
    localeUrl(url) {
        if (this.$_getLocale() === "en") {
            url = new URL(url);
            let pathName = url.pathname;
            let search = url.search;
            return url.origin + "/en" + pathName + search;
        } else {
            return url;
        }
    },
}
