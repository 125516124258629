import Vue from "vue";
export default {
  SET_BOOKMARK(state, data) {
    Vue.set(state, "bookmarks", data);
  },
  SET_LOADING_BOOKMARK(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  DELETE_BOOKMARK(state, bookmarkId) {
    const index = state.bookmarks.findIndex((x) => x.id === bookmarkId);
    Vue.delete(state.bookmarks,index);
    if(state.bookmarks.length === 0){
      Vue.set(state.pagination, 'page', state.pagination.page - 1);
    }
  },
};
