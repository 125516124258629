import Vue from "vue";
export default {
  SET_PORTFOLIOS(state, data) {
    Vue.set(state, "portfolios", data);
  },

  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_PORTFOLIO(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_PORTFOLIO(state, data) {
    Vue.set(state.portfolios, state.portfolios.length, data);
  },
  
  DELETE_PORTFOLIO(state, portfolioId) {
    const index = state.portfolios.findIndex((x) => x.id === portfolioId);
    Vue.delete(state.portfolios,index);
    if(state.portfolios.length === 0){
      Vue.set(state.pagination, 'page', state.pagination.page - 1);
    }
  },
  UPDATE_PORTFOLIO(state, data) {
    const index = state.portfolios.findIndex((x) => x.id === data.id);
    Vue.set(state.portfolios, index, data);
  },
  MERGE_PORTFOLIO(state,data){
    const index = state.portfolios.findIndex((x) => x.id === data.id);
    if(index >=0){
      const newValuePortfolio = {...state.portfolios[index],...data};
      Vue.set(state.portfolios, index, newValuePortfolio);
    }
  },
  SET_LOADING_PORTFOLIO(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_IS_FILTERED_PORTFOLIO(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}
    state.isFiltered = false;
  },
};
