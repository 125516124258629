export default {
  getMyInfo: (state) => state.myInfo,
  getNotifications: (state) => state.notifications,
  getUsers: (state) => state.users,
  getPaginationUser: (state) => state.pagination,
  getUserNotSelected: (state) => state.userNotSelected,
  getAllUserSelected: (state) => state.allUserSelected,
  getUserSelected: (state) => {
    if (state.allUserSelected) {
      return state.users.filter((user) => {
        return state.userNotSelected.findIndex((x) => x.id === user.id) < 0;
      });
    } else {
      return state.userSelected;
    }
  },
  isFilteredUser: (state) => state.isFiltered,
  getSorts : state => state.sorts,
  getFiltersUser: (state) => state.filters,
  getUserLoading: (state) => state.loading,
  getSelectAllCheckbox: (state) => state.selectAllCheckbox,
};
