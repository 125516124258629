import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify)

const opts = {
    theme: {
        themes: {
            light: {
                cyan: '#32CAD5', // #3F51B5,
                bgCyan: '#e4fdff',
                SonicSilver: '#777777'
            },
        },
    },
}

export default new Vuetify(opts)

