/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Paths from "./path"
import {TokenStorage, UserStorage} from "../utils/storage";
Vue.use(Router);
Vue.use(Meta);

let router = null;

create();
beforeLoad();


export default router;

function create() {
    router = new Router({
        mode: "history",
        routes: Paths
            .map((path) => route(path)),
        scrollBehavior(to, from, savedPosition) {
            if (savedPosition) {
                return savedPosition;
            }
            if (to.hash) {
                return { selector: to.hash };
            }
            return { x: 0, y: 0 };
        },
    });
}

function route(options) {
    let path = options.path;
    let view = options.view;
    let name = options.name;
    let props = !!options.props;
    let meta = options.meta ? options.meta : "";
    return {
        name: name || view,
        path,
        props,
        meta,
        component: () =>
            import ("@/views/" + view + '.vue'),
    };
}

function beforeLoad() {
    router.beforeEach((to, from, next) => {
        setTitle(to);

        const auth = to.meta && to.meta.auth ? to.meta.auth : false;
        const types = to.meta && to.meta.types ? to.meta.types : false;


        const loggedIn = !!TokenStorage.getToken();

        if(to.name === "login" && loggedIn){
            router.push({ name: 'dashboard', params: {locale: 'en'}});
        }

        if (auth && !loggedIn) {
            if (to.fullPath.split('/')[1] === 'en') {
                router.push({ name: 'login', params: {locale: 'en'}, query: { redirect: to.fullPath } });
            } else {
                router.push({ name: 'login', query: { redirect: to.fullPath } });
            }
        }

        if (loggedIn && types && Array.isArray(types)) {
            const ownType = UserStorage.get() ? UserStorage.get().type : '';
            if (ownType && !types.includes(ownType)) {
                router.push({ name: 'dashboard', params: {locale: 'en'}});
            }
        }


        next();
    });
}

function setTitle(to) {
    const DEFAULT_TITLE = "Liliana";
    document.title = to.meta.title || DEFAULT_TITLE;
}
