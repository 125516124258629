import Vue from "vue";
export default {
  SET_MODELS(state, data) {
    Vue.set(state, "models", data);
  },
  SET_FILTER_MODEL(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  SET_SORT_MODEL(state, data) {
    Vue.set(state, "sorts", data);
    state.pagination.page = 1
  },
  SET_PAGINATION(state,{ pagination}) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  UPDATE_MODEL(state, data) {
    const index = state.models.findIndex((x) => x.id === data.id);
    Vue.set(state.models, index, data);
  },
  REMOVE_MODEL(state, modelId) {
    const index = state.models.findIndex((x) => x.id === modelId);
    state.models.splice(index, 1);
  },
  UPDATE_SPECIAL_VIEW(state, model) {
    const index = state.models.findIndex((x) => x.id === model.id);
    Vue.set(state.models, index, model);
  },
  UPDATE_ATTR_VALUES(state, data) {
    const index = state.models.findIndex((x) => x.id === data.id);
    Vue.set(state.models[index], "attributeValuesId", data.values);
  },
  REMOVE_MODEL_SELECTED(state, modelId) {
    let index = state.modelSelected.findIndex(x => x.id === modelId);
    Vue.delete(state.modelSelected,index);
  },
  REMOVE_MODEL_FROM_NOT_SELECTED(state, modelId){
    let index = state.modelNotSelected.findIndex(x => x.id === modelId);
    Vue.delete(state.modelNotSelected,index);
  },
  SET_LOADING_MODEL(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_IS_FILTERED_MODEL(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DELETE_SELECTED_MODELS(state,data){
    if(state.allModelSelected){
      const notSelectedModels = [];
      state.models.map((model)=>{
        if(data.expect.includes(model.id)){
          notSelectedModels.push(model);
        }
      })
      Vue.set(state,"models",notSelectedModels);
    }else{
      state.modelSelected.map(model=>{
        let index = state.models.findIndex(x => x.id === model.id);
        Vue.delete(state.models,index);
      })
    }
  },
  DELETE_FROM_SELECTED(state, modelId) {
    if(state.allModelSelected){
      let index = state.modelNotSelected.findIndex(x => x.id === modelId);
      Vue.delete(state.modelNotSelected,index);
    }else if(state.modelSelected.length){
      let index = state.modelSelected.findIndex(x => x.id === modelId);
      Vue.delete(state.modelSelected,index);
    }
  },
  DESTROY_HISTORY(state){
    state.filters = {
      model_verified_at: {
      type: "in",
      val: null,
      name : "model verified at",
      value :"all",
    },}
    state.isFiltered = false;
    state.modelSelected = [],
    state.modelNotSelected = [],
    state.allModelSelected = false,
    state.select_all_checkbox = []
    state.pagination = {itemsPerPage: 15}
  },
  FROM_ZERO(state,data){
    state.fromZero = data
  }
};
