import Vue from "vue";
export default {
  SET_BLOGS(state, data) {
    Vue.set(state, "blogs", data);
  },

  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_BLOG(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1;
  },
  ADD_BLOG(state, data) {
    Vue.set(state.blogs, state.blogs.length, data);
  },

  DELETE_BLOG(state, blogId) {
    const index = state.blogs.findIndex((x) => x.id === blogId);
    Vue.delete(state.blogs, index);
    if (state.blogs.length === 0) {
      Vue.set(state.pagination, "page", state.pagination.page - 1);
    }
  },
  UPDATE_BLOG(state, data) {
    const index = state.blogs.findIndex((x) => x.id === data.id);
    if (index >= 0) {
      let blog = { ...state.blogs[index], ...data };
      Vue.set(state.blogs, index, blog);
    }
  },
  SET_LOADING_BLOG(state, status) {
    Vue.set(state, "loading", status);
  },
  SET_IS_FILTERED_BLOG(state, status) {
    Vue.set(state, "isFiltered", status);
  },  
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}
    state.isFiltered = false;
  },
};
