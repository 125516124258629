import ClientRepository from "@/abstraction/repository/clientRepository";
import UserRepository from "@/abstraction/repository/userRepository";
let clientRepository = new ClientRepository();
let userRepository = new UserRepository();
import Vue from "vue"

export default {
  async loadClients({ state, commit }) {
    try {
      commit("SET_LOADING_CLIENT", true);
      let data = { pagination: state.pagination, filters: state.filters ,sorts: state.sorts  };
      const resource = await clientRepository.index(data);
      commit("SET_CLIENTS", resource.data);
      commit("SET_PAGINATION", { pagination: resource.pagination });
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_CLIENT", false);
    }
  },

  async createClient({ commit }, data) {
    const resource = await clientRepository.createClient(data);
    commit("ADD_CLIENT", resource);
  },

  async updateClient({ commit }, data) {
    const resource = await clientRepository.update(data.id,data);
    commit("UPDATE_CLIENT", resource);
  },

  async deleteClient({ commit }, clientId) {
    await userRepository.delete(clientId);
    commit("DELETE_CLIENT", clientId);
  },

  async changeRoll({commit},data){
    await userRepository.changeRoll(data.id,data.type);
    commit("UPDATE_CLIENT", data);
  },

  async sendEmail({state}, data) {
    if(state.isFiltered && state.allClientSelected){
      data.filters = state.filters
      data.expect = state.clientNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.allClientSelected){
      data.expect = state.clientNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.clientSelected.length){
      data.ids = state.clientSelected.map(({id})=>(id))
    }else if(data.id){
      data.ids = [data.id]
    } else {
      data.ids = ["all"]
    }

    return await userRepository.sendEmailToClients(data);
  },

  toggleSelectAll ({state}){
    if(state.allClientSelected){
      state.clientSelected = []
      state.clientNotSelected = []
    }
    state.allClientSelected = !state.allClientSelected;
  },

  addToSelected({state},data){
    if(state.allClientSelected){
      const index = state.clientNotSelected.findIndex((x) => x.id === data.id)
      Vue.delete(state.clientNotSelected, index);
    }
  },

  removeFromSelected({state},data){
    if(state.allClientSelected){
      state.clientNotSelected.push(data)
    }
  },

  removeFromNotSelected({commit},client){
    commit("REMOVE_CLIENT_FROM_NOT_SELECTED", client.id)
  },

  setIsFiltered({commit},data){
    commit("SET_IS_FILTERED_MODEL", data)
  },


};
