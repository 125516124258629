import ContactRepository from "@/abstraction/repository/contactRepository";
let contactRepository = new ContactRepository();
export default {
  async loadContactMessages({ state, commit }) {
    try {
      commit("SET_LOADING_CONTACT", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await contactRepository.index(data);
      commit("SET_CONTACT_MESSAGES", resource.data);
      commit("SET_PAGINATION", { pagination: resource.pagination });
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_CONTACT", false);
    }
    
  },
};
