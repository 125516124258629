export default {
  jobOffers: [],
  responses: [],
  modelSelection: [],
  paginationJobOffers: {
    itemsPerPage: 15,
  },
  paginationResponse: {
    itemsPerPage: 15,
  },
  modelSelectionPagination: {
    itemsPerPage: 15,
  },
  jobOfferFilter: {},
  responseFilter: {},
  responseStatuses: [],
  responseSelected: [],
  allResponseSelected: false,
  responseNotSelected: [],
  isFiltered: false,
  loadingOffer: false,
  loadingResponse: false,
  isFilteredResponse: false,
  modelSelectionFilter: {}
}