import emailTemplateRepository from "@/abstraction/repository/emailTemplateRepository";
let templateRepository = new emailTemplateRepository();
export default {
  async loadTemplates({ state, commit }) {
    try {
      commit("SET_LOADING_TEMPLATE", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await templateRepository.index(data);
      commit("SET_TEMPLATES", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_TEMPLATE", false);
    }
    
  },
  async storeTemplate({ commit }, data) {
    const resource = await templateRepository.store(data);
    commit("ADD_TEMPLATE", resource);
  },
  async updateTemplate({ commit }, data) {
    const resource = await templateRepository.update(data.id, data);
    commit("UPDATE_TEMPLATE", resource);
  },
  async deleteTemplate({ commit }, templateId) {
    await templateRepository.delete(templateId);
    commit("DELETE_TEMPLATE", templateId);
  },  
  setIsFiltered({commit},data){
    commit("SET_IS_FILTERED_TEMPLATE", data)
  }
};
