import "willatoast/dist/main.css";
import willaToast from "willatoast";

const toast = {
    error: (message, title = "Error") => {
        return willaToast.normal({
            id: 'error',
            displayMode: 'replace',
            title: title,
            message: message,
            position: "bottomCenter"
        });
    },
    success: (message, title = "Success") => {
        return willaToast.normal({
            id: 'error',
            displayMode: 'replace',
            title: title,
            message: message,
            position: "bottomCenter"
        });
    },
    question: (message, title = "Success", callback) => {
        return willaToast.normal({
            title: title,
            message: message,
            overlay: true,
            position: "center",
            timeout: false,
            theme: 'dark',
            buttons: [
                ['<button style="border: 1px dashed white;" >yes</button>', function (instance, toast) {
                    callback();
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                }, true],
                ['<button>no</button>', function (instance, toast) {

                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                }]
            ],
        });
    },
    s_question: (options, callback) => {
        return willaToast.normal({
            ...options,
            buttons: [
                ['<button style="border: 1px solid black;background-color: white;color:black;font-family: montserrat-regular;padding: 4px 60px;"><i style="color:black;" class="WMi-ok"></i>YES, I AM SURE</button>', function (instance, toast) {
                    callback();
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                }, true],
                ['<button style="background-color: #ee35511c;color:#EE3552;font-family: montserrat-regular;padding: 6px 19px;"><i style="color:#EE3552;" class="WMi-cancel"></i> NO WAIT!</button>', function (instance, toast) {

                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');

                }]
            ],
        });
    }
};

export default toast;