import UserRepository from "@/abstraction/repository/userRepository";
import { UserStorage } from "@/utils/storage";
let userRepository = new UserRepository();
import ClientRepository from "@/abstraction/repository/clientRepository";
let clientRepository = new ClientRepository();
import Vue from "vue";

export default {
  async loadMyInfo({ commit }) {
    try {
      const resource = await userRepository.myInfo();

      if (resource) {
        commit("SET_MY_INFO", resource);
        UserStorage.save(resource);
      }
    } catch (e) {
      console.log(e);
      return e;
    }
  },
  async loadUsers({ state, commit }) {
    try {
      if(state.users.length===0) {
        commit("SET_LOADING_USER", true);
      }
      let data = { pagination: state.pagination, filters: state.filters, sorts: state.sorts };
      const resource = await userRepository.index(data);
      let users = [];
      if(state.users.length===0){
        users = resource.data
      } else {
        if(state.isFiltered==1 && state.pagination>1){
          users = resource.data
        } else {
          users = state.users
          users.push(...resource.data)
        }
      }
      commit("SET_USERS", users);
      commit("SET_PAGINATION", { pagination: resource.pagination });
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_USER", false);
    }
  },

  async loadNotifications({ commit }) {
    const notifications = await userRepository.dashboardNotifications();
    if (notifications) {
      commit("SET_NOTIFICATIONS", notifications);
    }
  },
  // async loadUsers({ state, commit }) {
  //   try {
  //     commit("SET_LOADING_USER", true);
  //     let data = { pagination: state.pagination, filters: state.filters };
  //     const resource = await userRepository.index(data);
  //     commit("SET_USERS", resource.data);
  //     commit("SET_PAGINATION", { pagination: resource.pagination });
  //   } catch (e) {
  //     return e;
  //   } finally {
  //     commit("SET_LOADING_USER", false);
  //   }
  // },

  async changeRole({commit},data){
    await userRepository.changeRoll(data.id,data.type);
    commit("UPDATE_USER", data);
  },

  async sendEmail({state}, data) {
    if(state.isFiltered && state.allUserSelected){
      data.filters = state.filters
      data.expect = state.userNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.allUserSelected){
      data.expect = state.userNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.userSelected.length){
      data.ids = state.userSelected.map(({id})=>(id))
    }else if(data.id){
      data.ids = [data.id]
    } else {
      data.ids = ["all"]
    }

    return await userRepository.sendEmailToUsers(data);
  },

  async createUser({ commit }, data) {
    const resource = await clientRepository.createClient(data);
    commit("ADD_USER", resource);
  },

  toggleSelectAll ({state}){
    if(state.allUserSelected){
      state.userSelected = []
      state.userNotSelected = []
    }
    state.allUserSelected = !state.allUserSelected;
  },

  addToSelected({state},data){
    if(state.allUserSelected){
      const index = state.userNotSelected.findIndex((x) => x.id === data.id)
      Vue.delete(state.userNotSelected, index);
    }
  },

  removeFromSelected({state},data){
    if(state.allUserSelected){
      state.userNotSelected.push(data)
    }
  },

  removeFromNotSelected({commit},user){
    commit("REMOVE_USER_FROM_NOT_SELECTED", user.id)
  },

  setIsFiltered({commit},data){
    commit("SET_IS_FILTERED_USER", data)
  },

  async deleteUser({ commit }, usrId) {
    await userRepository.delete(usrId);
    commit("DELETE_USER", usrId);
    commit("DELETE_FROM_SELECTED", usrId);
  },


  async updateUser({ commit }, data) {
    const resource = await clientRepository.update(data.id,data);
    commit("UPDATE_USER", resource);
  },
};
