import BlogRepository from "@/abstraction/repository/blog/blogRepository";
const blogRepository = new BlogRepository();
export default {
  async loadBlogs({ state, commit }) {
    try {
      commit("SET_LOADING_BLOG", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await blogRepository.index(data);
      commit("SET_BLOGS", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_BLOG", false);
    }
  },

  async storeBlog({ commit }, data) {
    try {
      const response = await blogRepository.store(data);
      commit("ADD_BLOG", response);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async verifyBlog({ commit }, data) {
    try {
      const response = await blogRepository.verify(data.id,data);
      commit("UPDATE_BLOG", response);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async updateBlog({ commit }, data) {
    const resource = await blogRepository.update(data.id, data);
    commit("UPDATE_BLOG", resource);
  },
  
  async deleteBlog({ commit }, blogId) {
    await blogRepository.delete(blogId);
    commit("DELETE_BLOG", blogId);
  },
  setIsFiltered({commit},status){
    commit("SET_IS_FILTERED_BLOG", status);
  }
};
