import Vue from "vue";
export default {
  SET_MANAGERS(state, data) {
    Vue.set(state, "managers", data);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_MANAGER(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_MANAGER(state, data) {
    state.managers.push(data);
  },
  DELETE_MANAGER(state, id) {
    const index = state.managers.findIndex((x) => x.id === id);
    state.managers.splice(index, 1);
  },
  UPDATE_MANAGER(state, data) {
    const index = state.managers.findIndex((x) => x.id === data.id);
    Vue.set(state.managers, index, data);
  },  
  SET_LOADING_MANAGER(state, status){
    Vue.set(state, 'loading', status);
  },  
  SET_IS_FILTERED_MANAGER(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}
    state.isFiltered = false;
  },
};
