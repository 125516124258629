import Vue from "vue";
export default {
  SET_BRANDS(state, data) {
    Vue.set(state, "brands", data);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_BRAND(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_BRAND(state, data) {
    Vue.set(state.brands, state.brands.length, data);
    Vue.set(state.pagination, 'itemsLength', state.pagination.itemsLength + 1);
    Vue.set(state.pagination, 'pageStop', state.pagination.pageStop + 1);
  },
  DELETE_BRAND(state, brandId) {
    const index = state.brands.findIndex((x) => x.id === brandId);
    Vue.delete(state.brands,index);
    if(state.brands.length === 0){
      Vue.set(state.pagination, 'page', state.pagination.page - 1);
    }
  },
  UPDATE_BRAND(state, data) {
    const index = state.brands.findIndex((x) => x.id === data.id);
    Vue.set(state.brands, index, data);
  },
  SET_LOADING_BRAND(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_IS_FILTERED_BRAND(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}
    state.isFiltered = false;
  },
};
