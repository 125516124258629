export default {
  myInfo: {},
  notifications : {},
  users: [],
  pagination: {
    itemsPerPage: 15,
  },
  allUserSelected : false,
  filters: {
    created_at: {
      name : "date",
      type: "between",
      val1: null,
      val2 :null,
    },
  },
  userSelected : [],
  userNotSelected : [],
  isFiltered : false,
  loading : true,
  sorts : "created_at,desc",
  selectAllCheckbox : [],
};
