import Vue from "vue";
export default {
  SET_TEMPLATES(state, data) {
    Vue.set(state, "templates", data);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_TEMPLATE(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_TEMPLATE(state, data) {
    Vue.set(state.templates, state.templates.length, data);
  },
  DELETE_TEMPLATE(state, templateId) {
    const index = state.templates.findIndex((x) => x.id === templateId);
    state.templates.splice(index, 1);
  },
  UPDATE_TEMPLATE(state, data) {
    const index = state.templates.findIndex((x) => x.id === data.id);
    Vue.set(state.templates, index, data);
  },
  SET_LOADING_TEMPLATE(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_IS_FILTERED_TEMPLATE(state, status){
    Vue.set(state, 'isFiltered', status);
  },
};
