import Vue from "vue";
export default {
  SET_CLIENTS(state, data) {
    Vue.set(state, "clients", data);
  },
  SET_DETAILS(state, data) {
    Vue.set(state, "details", data);
  },
  SET_FILTER_CLIENT(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  SET_SORT_CLIENT(state, data) {
    Vue.set(state, "sorts", data);
    state.pagination.page = 1
  },
  SET_IS_FILTERED_CLIENT(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  SET_PAGINATION(state, { pagination }) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  DELETE_CLIENT(state, clientId) {
    const index = state.clients.findIndex((x) => x.id === clientId);
    state.clients.splice(index, 1);
  },
  ADD_CLIENT(state, data) {
    Vue.set(state.clients, state.clients.length, data);
  },
  UPDATE_CLIENT(state, data) {
    const index = state.clients.findIndex((x) => x.id === data.id);
    Vue.set(state.clients, index, data);
  },
  REMOVE_CLIENT_SELECTED(state, clientId) {
    let index = state.clientSelected.findIndex(x => x.id === clientId);
    Vue.delete(state.clientSelected,index);
  },
  REMOVE_CLIENT_FROM_NOT_SELECTED(state, clientId){
    let index = state.clientNotSelected.findIndex(x => x.id === clientId);
    Vue.delete(state.clientNotSelected,index);
  },
  SET_LOADING_CLIENT(state, status){
    Vue.set(state, 'loading', status);
  }
};
