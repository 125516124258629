export default {
  clients: [],
  details: {},
  pagination: {
    itemsPerPage: 15,
  },
  allClientSelected : false,
  filters: {},
  clientSelected : [],
  clientNotSelected : [],
  isFiltered : false,
  sorts: "",
  loading : false,
};
