import Vue from "vue";
export default {
  SET_MY_INFO(state, data) {
    Vue.set(state, "myInfo", data);
  },
  SET_NOTIFICATIONS(state, data) {
    Vue.set(state, "notifications", data);
  },
  SET_LOADING_USER(state, status) {
    Vue.set(state, "loading", status);
  },
  SET_USERS(state, data) {
    Vue.set(state, "users", data);
  },
  SET_PAGINATION(state, { pagination }) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_IS_FILTERED_USER(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  SET_SORT_USER(state, data) {
    Vue.set(state, "sorts", data);
    state.pagination.page = 1
  },
  REMOVE_USER_SELECTED(state, userId) {
    let index = state.userSelected.findIndex(x => x.id === userId);
    Vue.delete(state.userSelected,index);
  },
  REMOVE_USER_FROM_NOT_SELECTED(state, userId){
    let index = state.userNotSelected.findIndex(x => x.id === userId);
    Vue.delete(state.userNotSelected,index);
  },
  SET_FILTER_USER(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_USER(state, data) {
    Vue.set(state.users, state.users.length, data);
  },
  UPDATE_USER(state, data) {
    const index = state.users.findIndex((x) => x.id === data.id);
    Vue.set(state.users, index, data);
  },
  DELETE_USER(state, userId) {
    const index = state.users.findIndex((x) => x.id === userId);
    state.users.splice(index, 1);
  },
  DELETE_FROM_SELECTED(state, userId) {
    if(state.allUserSelected){
      let index = state.userNotSelected.findIndex(x => x.id === userId);
      Vue.delete(state.userNotSelected,index);
    }else if(state.userSelected.length){
      let index = state.userSelected.findIndex(x => x.id === userId);
      Vue.delete(state.userSelected,index);
    }
  },
  DESTROY_HISTORY(state){
    state.filters = {
      created_at: {
        type: "date",
        name : "created_at",
        val1: null,
        val2: null,
      },
    };
    state.pagination = {itemsPerPage: 15};
    state.pagination = {itemsPerPage: 15};
    state.allUserSelected = false;
    state.userSelected = [];
    state.userNotSelected = [];
    state.isFiltered = false;
    state.selectAllCheckbox = [];
  },
};
