import ProjectRepository from "@/abstraction/repository/projectRepository";
let projectRepository = new ProjectRepository();
export default {
  async loadProjects({ state, commit }) {
    try {
      commit("SET_LOADING_PROJECT", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await projectRepository.index(data); 
      commit("SET_PROJECTS", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      console.log(e);
      return e;
    } finally {
      commit("SET_LOADING_PROJECT", false);
    }
  },
  async storeProject({ commit }, data) {
    const resource = await projectRepository.store(data);
    commit("ADD_PROJECT", resource);
    if (resource) return true;
  },
  async updateProject({ commit }, data) {
    const resource = await projectRepository.update(data.id, data);
    if (resource) {
      commit("UPDATE_PROJECT", data); 
    }
    return resource;
  },
  async deleteManager({ commit }, projectId) {
    await projectRepository.delete(projectId);
    commit("DELETE_PROJECT", projectId);
  },
};
