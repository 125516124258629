export default {
  getModels: (state) => state.models,
  getPaginationModel: (state) => state.pagination,
  getModelSelected: state => {
    if(state.allModelSelected){
      return state.models.filter((model)=>{
        return state.modelNotSelected.findIndex(x=>x.id===model.id) < 0;
      })
    }else{
      return state.modelSelected
    }
  },
  getModelNotSelected : state => state.modelNotSelected,
  getAllModelSelected : state => state.allModelSelected,
  getFiltersModel : state => state.filters,
  isFilteredModel : state => state.isFiltered,
  getSorts : state => state.sorts,
  getModelLoading : state => state.loading,
  getSelectAllCheckbox : state => state.select_all_checkbox,
};
