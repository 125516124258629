import HairColorRepository from "@/abstraction/repository/hairColorRepository";
let hairColorRepository = new HairColorRepository();
export default {
  async loadHairColors({ commit, state }) {
    if (state.hairColors.length) return;

    const resource = await hairColorRepository.index();
    commit("SET_HAIR_COLORS", resource.data);
  },
};
