import GalleryImageRepository from "@/abstraction/repository/galleryImageRepository";
let galleryImageRepository = new GalleryImageRepository();
export default {
  async loadGalleryImages({ state, commit }) {
    try {
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await galleryImageRepository.index(data);
      commit("SET_GALLERY", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    }
  },
  async storeGalleryImage({ commit }, data) {
    const resource = await galleryImageRepository.store(data);
    commit("ADD_BRAND", resource);
    if (resource) return true;
  },
  async updateGalleryImage({ commit }, data) {
    const resource = await galleryImageRepository.update(data.id, data);
    commit("UPDATE_BRAND", resource);
    if (resource) return true;
  },
  async deleteGalleryImage({ commit }, galleryId) {
    await galleryImageRepository.delete(galleryId);
    commit("DELETE_BRAND", galleryId);
  },
};
