import RequestRepository from "@/abstraction/repository/requestRepository";
let requestRepository = new RequestRepository();
export default {
  async loadRequests({ state, commit }) {
    try {
      commit("SET_LOADING_REQUEST", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await requestRepository.index(data);
      commit("SET_REQUESTS", resource.data);
      commit("SET_PAGINATION", { pagination: resource.pagination });
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_REQUEST", false);
    }
  },
  async storeRequest({ commit }, data) {
    const resource = await requestRepository.store(data);
    commit("ADD_REQUEST", resource);
  },
  async deleteRequest({ commit }, requestId) {
    await requestRepository.delete(requestId);
    commit("DELETE_REQUEST", requestId);
  },

  async updateRequestStatus({ commit }, data) {
    let resource = await requestRepository.updateStatus(data.id, data.status.id);
    commit("UPDATE_REQUEST", resource);
  },
};
