const TOKEN_KEY = "laravel_authorize_token";
const USER = "user";
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to store. Local Storage should always be
 * accessed through this instace.
 **/
import StoreManagement from "./StoreManagement";
const TokenStorage = {
  getToken() {
    return StoreManagement.get(TOKEN_KEY);
  },

  saveToken(accessToken = null) {
    StoreManagement.set(TOKEN_KEY, 'Bearer ' + accessToken);
  },

  removeToken() {
    StoreManagement.remove(TOKEN_KEY);
  },

  hasToken() {
    return StoreManagement.get(TOKEN_KEY) ? true : false;
  },
};

const UserStorage = {
  get() {
    if (StoreManagement.get(USER)) {
      return StoreManagement.get(USER);
    }
    return null;
  },

  save(user = null) {
    StoreManagement.set(USER, user);
  },

  remove() {
    StoreManagement.remove(USER);
  },
};

export { TokenStorage, UserStorage };
