import Vue from "vue";
export default {
  SET_PROJECTS(state, data) {
    Vue.set(state, "projects", data);
  },
  SET_PAGINATION(state, pagination) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_FILTER_PROJECT(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  ADD_PROJECT(state, data) {
    state.projects.push(data);
  },
  DELETE_PROJECT(state, data) {
    const index = state.projects.findIndex((x) => x.id === data.id);
    Vue.set(state.projects, index, data);
  },
  UPDATE_PROJECT(state, data) {
    const index = state.projects.findIndex((x) => x.id === data.id);
    Vue.set(state.projects, index, data);
  },
  SET_LOADING_PROJECT(state, status){
    Vue.set(state, 'loading', status);
  },
  SET_IS_FILTERED_PROJECT(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}
    state.isFiltered = false;
  },
};
