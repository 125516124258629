import CountryRepository from "@/abstraction/repository/countryRepository";
let countryRepository = new CountryRepository();
export default {
  async loadCountries({ commit, state }) {
    
    if (state.countries.length) return;

    const resource = await countryRepository.index();
    commit("SET_COUNTRIES", resource.data);
  },
};
