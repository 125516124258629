import BookmarkRepository from "@/abstraction/repository/bookmarkRepository";
let bookmarkRepository = new BookmarkRepository();
export default {
  async loadBookmarks({ state,commit }) {
    try {
      commit("SET_LOADING_BOOKMARK", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await bookmarkRepository.index(data);
      commit("SET_BOOKMARK", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_BOOKMARK", false);
    }
  },
  async deleteBookmark({ commit }, modelId) {
    await bookmarkRepository.sync(modelId);
    commit("DELETE_BOOKMARK", modelId);
  },
};
