export default {
  models: [],
  pagination: {
    itemsPerPage: 15,
  },
  allModelSelected : false,
  filters: {
    model_verified_at: {
      type: "in",
      val: null,
      name : "model verified at",
      value :"all",
    },
  },
  loading : false,
  sorts : "model_updated_not_appended,desc",
  modelSelected : [],
  modelNotSelected : [],
  isFiltered : false,
  select_all_checkbox : [],
  fromZero : false,
};
