import Vue from 'vue';
import Tile from '../components/Global/Section/Tile.vue';
import Header from '../components/Global/Header/Header.vue'
import Footer from '../components/Global/Footer/Footer.vue';
import Back from '../components/Global/Header/Back.vue';
import BlockButton from '../components/Global/Button/BlockButton.vue';
import List from '../components/Global/Section/List.vue';
import CircleButton from '../components/Global/Button/CircleButton.vue';
import MenuItem from '../components/Global/Button/MenuItem.vue';
import Divider from '../components/Global/Section/Divider.vue';
import DateInput from '../components/Global/Input/DateInput.vue';
import TimeInput from '../components/Global/Input/TimeInput.vue';
import Checkbox from '../components/Global/Input/Checkbox.vue';
import BasicModal from "../components/Global/Modal/BasicModal";
import Loading from "../components/Global/Misc/Loading";
import NoItems from "../components/Global/Misc/NoItems";
import Pagination from "../components/Global/Pagination/Pagination.vue";
import DataIterator from "../components/Global/Input/DataIterator.vue";
import VueLazyLoad from 'vue-lazyload'
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import "animate.css"
import '@Styles/icon/fontello-b9d25c07/css/fontello.css';
import 'vue-image-lightbox/dist/vue-image-lightbox.min.css';


Vue.component('data-iterator', DataIterator);
Vue.component('basic-modal', BasicModal);
Vue.component('site-header', Header);
Vue.component('tile', Tile);
Vue.component('site-footer', Footer);
Vue.component('block-button', BlockButton);
Vue.component('circle-button', CircleButton);
Vue.component('menu-item', MenuItem);
Vue.component('divider', Divider);
Vue.component('date-input', DateInput);
Vue.component('time-input', TimeInput);
Vue.component('Checkbox', Checkbox);
Vue.component('loading', Loading);
Vue.component('no-items', NoItems);
Vue.component('list', List); Pagination
Vue.component('back', Back);
Vue.component('pagination', Pagination);
Vue.use(VueLazyLoad);