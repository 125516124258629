export default {
  requests: [],
  details: {},
  pagination: {
    itemsPerPage: 15,
  },
  filters: {},
  loading : false,
  isFiltered : false,
};
