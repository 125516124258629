import Vue from "vue";
export default {
  SET_CONTACT_MESSAGES(state, data) {
    Vue.set(state, "contactMessages", data);
  },
  SET_FILTER_CONTACT_MESSAGE(state, data) {
    Vue.set(state, "filters", data);
    state.pagination.page = 1
  },
  SET_PAGINATION(state, { pagination }) {
    pagination = { ...state.pagination, ...pagination };
    Vue.set(state, "pagination", pagination);
  },
  SET_LOADING_CONTACT(state, status){
    Vue.set(state, 'loading', status);
  },  
  SET_IS_FILTERED_CONTACT(state, status){
    Vue.set(state, 'isFiltered', status);
  },
  DESTROY_HISTORY(state){
    state.filters = {}
    state.pagination = {itemsPerPage: 15}    
    state.isFiltered = false;
  },
};
