import AuthRepository from "@/abstraction/repository/authRepository";
import {TokenStorage, UserStorage} from "@/utils/storage";
import ApiService from "@/utils/axios/api";
let authRepository = new AuthRepository();
import JobOfferRepository from "@/abstraction/repository/jobOfferRepository";
const jobOfferRepository = new JobOfferRepository();

function setTokenHeader(commit, response) {
    TokenStorage.saveToken(response.token);
    UserStorage.save(response.user);
    commit("SET_AUTH_USER", response.user);
    ApiService.setAuthHeader();
}

export default {
    async login({commit}, data) {
        const response = await authRepository.login(data);
        if (response) {
            setTokenHeader(commit, response);
            return response;
        }
    },
    async loginWithJobOfferCode({commit}, data) {
        const response = await jobOfferRepository.loginWithJobOfferLink(data);
        if (response) {
            setTokenHeader(commit, response);
            return response;
        }
    },
    async logout() {
        await authRepository.logout();
        ApiService.removeAuthHeader();
        TokenStorage.removeToken();
        UserStorage.remove();
        return true;
    },
    async verifyEmail({commit},data){
        const response = await authRepository.verifyCode(data);
        if (response) {
            setTokenHeader(commit, response);
            return response;
        }
    },
    
    async emailVerify({commit},data){
        const response = await authRepository.forgotPasswordVerify(data)
        if (response) {
            setTokenHeader(commit, response);
            return response;
        }
    }
};
