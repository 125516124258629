export default {
  getClients: (state) => state.clients,
  getDetails: (state) => state.details,
  getPaginationClient: (state) => state.pagination,
  getClientNotSelected : state => state.clientNotSelected,
  getAllClientSelected : state => state.allClientSelected,
  getClientSelected: state => {
    if(state.allClientSelected){
      return state.clients.filter((client)=>{
        return state.clientNotSelected.findIndex(x=>x.id===client.id) < 0;
      })
    }else{
      return state.clientSelected
    }
  },
  isFilteredClient : state => state.isFiltered,
  getFiltersClient : state => state.filters,
  getClientLoading : state => state.loading,
  getSorts : state => state.sorts,
};
