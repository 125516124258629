import Vue from 'vue';
export default {
    SET_ATTRIBUTES(state, attributes) {
        Vue.set(state, 'attributes', attributes);
    },
    ADD_ATTRIBUTE(state, data) {
        Vue.set(state.attributes, state.attributes.length, data);
    },
    UPDATE_ATTRIBUTE(state, data) {
        const index = state.attributes.findIndex((x) => x.key === data.key);
        Vue.set(state.attributes, index, data);
    },

    DELETE_ATTRIBUTE(state, key) {
        const index = state.attributes.findIndex((x) => x.key === key);
        Vue.delete(state.attributes, index);
    },

    SET_ATTRIBUTE_VALUES(state, {attributeKey, attributeValues}) {
        const index = state.attributes.findIndex((x) => x.key === attributeKey);
        Vue.set(state.attributes[index], 'attributeValues', attributeValues);
    },
    ADD_ATTRIBUTE_VALUE(state, {attributeKey, attributeValue}) {
        const index = state.attributes.findIndex((x) => x.key === attributeKey);
        Vue.set(state.attributes[index].attributeValues, state.attributes[index].attributeValues.length, attributeValue);
    },
    UPDATE_ATTRIBUTE_VALUE(state, {attributeKey, attributeValue}) {
        const id = state.attributes.findIndex((x) => x.key === attributeKey);
        const index = state.attributes[id].attributeValues.findIndex((x) => x.key === attributeValue.key);
        Vue.set(state.attributes[id].attributeValues, index, attributeValue);
    },
    DELETE_ATTRIBUTE_VALUE(state, {attributeKey, attributeValueKey}) {
        const id = state.attributes.findIndex((x) => x.key === attributeKey);
        const index = state.attributes[id].attributeValues.findIndex((x) => x.key === attributeValueKey);
        Vue.delete(state.attributes[id].attributeValues, index);
    },
}
