import ModelRepository from "@/abstraction/repository/modelRepository";
import UserRepository from "@/abstraction/repository/userRepository";
import SpecialViewRepository from "@/abstraction/repository/specialViewRepository";
import Vue from "vue"
let modelRepository = new ModelRepository();
let userRepository = new UserRepository();
let specialViewRepository = new SpecialViewRepository();
export default {
  async loadModels({ state, commit }) {
    try {
      if(state.models.length===0) {
        commit("SET_LOADING_MODEL", true);
      }
      let data = { pagination: state.pagination, filters: state.filters, sorts: state.sorts };
      const resource = await modelRepository.index(data);
      let models = [];
      if(state.models.length===0){
        models = resource.data
      } else {
        if(state.isFiltered==1 && state.pagination>1){
          models = resource.data
        } else {
          models = state.models
          models.push(...resource.data)
        }
      }
      commit("SET_MODELS", models);
      commit("SET_PAGINATION", { pagination: resource.pagination });
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_MODEL", false);
    }
  },
  async updateFromZero({ commit }, data) {
    commit("FROM_ZERO", data);
  },
  async updateByUser({ commit }, data) {
    const resource = await modelRepository.update(data);
    commit("UPDATE_MODEL", resource);
  },
  async updateByAdmin({ commit }, data) {
    await modelRepository.updateByAdmin(data.id, data);
    commit("UPDATE_MODEL", data);
  },
  async acceptUpdateByAdmin({ commit }, data) {
    await modelRepository.acceptUpdateByAdmin(data.id, data);
    commit("UPDATE_MODEL", data);
  },
  async rejectUpdateByAdmin({ commit }, data) {
    await modelRepository.rejectUpdateByAdmin(data.id, data);
    commit("UPDATE_MODEL", data);
  },
  async deleteModel({ commit }, modelId) {
    await userRepository.delete(modelId);
    commit("REMOVE_MODEL", modelId);
    commit("DELETE_FROM_SELECTED", modelId);
  },
  async delMultiModels({ state,commit }) {
    try {
      const data = {};
      if(state.isFiltered && state.allModelSelected){
        data.filters = state.filters
      }
      if(state.allModelSelected){
        data.expect = state.modelNotSelected.map(({id})=>(id))
        data.ids = ["all"]
      }else if(state.modelSelected){
        data.ids = state.modelSelected.map(({id})=>(id))
      }

      await modelRepository.destroyMultipleModels(data);
      commit("DELETE_SELECTED_MODELS",data);
    } catch (error) {
      console.log(error);
      return error;
    }
  },

  async updateSpecialView({ commit }, data) {
    await specialViewRepository.updateSpecialViews(data.id, data);
    commit("UPDATE_SPECIAL_VIEW", data);
  },
  async updateAttrValues({ commit }, data) {
    await userRepository.updateAttrValues(data.id,data.values);
    commit("UPDATE_ATTR_VALUES", data);
  },

  async sendEmail({state}, data) {
    if(state.isFiltered && state.allModelSelected){
      data.filters = state.filters
      data.expect = state.modelNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.allModelSelected){
      data.expect = state.modelNotSelected.map(({id})=>(id))
      data.ids = ["all"]
    }else if(state.modelSelected.length){
      data.ids = state.modelSelected.map(({id})=>(id))
    }else if(data.id){
      data.ids = [data.id]
    } else {
      data.ids = ["all"]
    }

    return await userRepository.sendEmailToModels(data);
  },

  async exportExcel({state},data) {
    let params = {} ;
    let filters = {};
    if(state.isFiltered && state.allModelSelected){
      filters = {filters : {...state.filters}}
      params.expect = state.modelNotSelected.map(({id})=>(id))
      params.ids = ["all"]
    }else if(state.allModelSelected){
      params.expect = state.modelNotSelected.map(({id})=>(id))
      params.ids = ["all"]
    }else if(state.modelSelected.length){
      params.ids = state.modelSelected.map(({id})=>(id))
    }else if(data && data.id){
      params.ids = [data.id]
    } else {
      params.ids = ["all"]
    }

    return await userRepository.exportExcel(params,filters);
  },

  toggleSelectAll ({state}){
    if(state.allModelSelected){
      state.modelSelected = []
      state.modelNotSelected = []
    }
    state.allModelSelected = !state.allModelSelected;
  },

  addToSelected({state},data){
    if(state.allModelSelected){
      const index = state.modelNotSelected.findIndex((x) => x.id === data.id)
      Vue.delete(state.modelNotSelected, index);
    }
  },

  removeFromSelected({state},data){
    if(state.allModelSelected){
      state.modelNotSelected.push(data)
    }
  },

  removeFromNotSelected({commit},model){
    commit("REMOVE_MODEL_FROM_NOT_SELECTED", model.id)
  },

  async updateModelVisibility({ commit }, data) {
    await modelRepository.updateVisibilityModel(data.id, data);
    data.model_verified_at = data.model_verified_at ?  null : Date.now();
    commit("UPDATE_MODEL", data);
  },

  setIsFiltered({commit},data){
    commit("SET_IS_FILTERED_MODEL", data)
  }
};
