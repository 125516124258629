import PortfolioRepository from "@/abstraction/repository/portfolio/portfolioRepository";
const portfolioRepository = new PortfolioRepository();
export default {
  async loadPortfolios({ state, commit }) {
    try {
      commit("SET_LOADING_PORTFOLIO", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await portfolioRepository.index(data);
      commit("SET_PORTFOLIOS", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      console.log(e);
      return e;
    } finally {
      commit("SET_LOADING_PORTFOLIO", false);
    }
  },

  async storePortfolio({ commit }, data) {
    try {
      const response = await portfolioRepository.store(data);
      commit("ADD_PORTFOLIO", response);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async verifyPortfolio({ commit }, data) {
    try {
      const response = await portfolioRepository.verify(data.id,data);
      commit("MERGE_PORTFOLIO", response);
      return response;
    } catch (error) {
      console.error(error);
      return error;
    }
  },

  async updatePortfolio({ commit }, data) {
    const resource = await portfolioRepository.update(data.id, data);
    commit("MERGE_PORTFOLIO", resource);
  },
  
  async deletePortfolio({ commit }, portfolioId) {
    await portfolioRepository.delete(portfolioId);
    commit("DELETE_PORTFOLIO", portfolioId);
  },
  setIsFiltered({commit},status){
    commit("SET_IS_FILTERED_PORTFOLIO", status);
  }
};
