import Cookie from './cookie'
import LocalStorage from './localStorage'
export default {
    set: (key, value) => {
        if (process.env.NODE_ENV === 'development') {
            LocalStorage.set(key, value);
        } else {
            Cookie.set(key, value);
        }
    },
    get: (key) => {
        if (process.env.NODE_ENV === 'development') {
            return LocalStorage.get(key);
        } else {
            return Cookie.get(key);
        }
    },
    remove: (key) => {
        if (process.env.NODE_ENV === 'development') {
            LocalStorage.remove(key);
        } else {
            Cookie.remove(key);
        }
    },
}
