import ManagerRepository from "@/abstraction/repository/managerRepository";
import UserRepository from "@/abstraction/repository/userRepository";
let managerRepository = new ManagerRepository();
let userRepository = new UserRepository();
export default {
  async loadManagers({ state, commit }) {
    try {
      commit("SET_LOADING_MANAGER", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await managerRepository.index(data);
      commit("SET_MANAGERS", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_MANAGER", false);
    }
  },

  async storeManager({ commit }, data) {
    const resource = await managerRepository.store(data);
    commit("ADD_MANAGER", resource);
    if (resource) return true;
  },
  async updateManager({ commit }, data) {
    const response = await managerRepository.update(data.id, data);
    if (response) {
      commit("UPDATE_MANAGER", response);
    }
    return response;
  },
  async deleteManager({ commit }, managerId) {
    await managerRepository.delete(managerId);
    commit("DELETE_MANAGER", managerId);
  },
  
  async changeRoll({commit},data){
    await userRepository.changeRoll(data.id,data.role);
    commit("DELETE_MANAGER", data.id);
  }
};
