export default [
    {
        path: '/:locale(en)?',
        view: 'Home',
        name: 'dashboard',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/profile',
        view: 'Profile',
        name: 'profile',
        meta: { auth: true, types: ['model', 'client'] }
    },
    {
        path: '/:locale(en)?/models',
        view: 'Models',
        name: 'models',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/gallery',
        view: 'ModelGalleries/ModelGallery',
        name: 'modelGallery',
        meta: { auth: true, types: ['model'] }
    },
    {
        path: '/:locale(en)?/model/:id/galleries',
        view: 'ModelGalleries/ModelGalleries',
        name: 'modelGalleries',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model-register',
        view: 'ModelRegister',
        name: 'modelRegister',
    },
    {
        path: '/:locale(en)?/model/registration',
        view: 'BecomeModel',
        name: 'modelRegistration',
        meta: { auth: true, types: ['model', 'client'] }
    },
    {
        path: '/:locale(en)?/model/registration/send',
        view: 'RequestSended',
        name: 'requestSended',
        meta: { auth: true, types: ['model', 'client'] }
    },
    {
        path: '/:locale(en)?/model/projects',
        view: 'ProjectList',
        name: 'projects',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/model/:model/projects',
        view: 'ProjectList',
        name: 'projectsModel',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailModel',
        name: 'modelDetail',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailProject',
        name: 'modelDetailProject',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailRequest',
        name: 'modelDetailRequest',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailOffer',
        name: 'modelDetailOffer',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailBlog',
        name: 'modelDetailBlog',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/model/:id/detail',
        view: 'Model/ModelDetailPortfolio',
        name: 'modelDetailPortfolio',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/requests',
        view: 'Request',
        name: 'requests',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/clients',
        view: 'Clients',
        name: 'clients',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/brands',
        view: 'Brands',
        name: 'brands',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/bookmarks',
        view: 'Bookmarks',
        name: 'bookmarks',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/managers',
        view: 'Managers',
        name: 'managers',
        meta: { auth: true, types: ['manager'] }
    },
    // auth
    {
        path: '/:locale(en)?/login',
        view: 'Login',
        name: 'login',
        meta: { auth: false }
    },
    {
        path: '/:locale(en)?/register',
        view: 'Register',
        name: 'register',
        meta: { auth: false }
    },
    {
        path: '/:locale(en)?/reset-password',
        view: 'PasswordReset',
        name: 'passwordReset'
    },
    // hiring model
    {
        path: '/:locale(en)?/hiring-model',
        view: 'HiringModel',
        name: 'hiringModel'
    },
    // contact us
    {
        path: '/:locale(en)?/contact-us',
        view: 'ContactUs',
        name: 'contactUs'
    },
    // edit model 
    {
        path: '/:locale(en)?/model/:id/model-edit',
        view: 'ModelEditByAdmin',
        name: 'modelEditByAdmin'
    },
    {
        path: '/:locale(en)?/model/model-edit',
        view: 'ModelEditByModel',
        name: 'modelEditByModel'
    },
    // offer
    {
        path: '/:locale(en)?/offers',
        view: 'JobOffer/Offers',
        name: 'offers'
    },
    {
        path: '/:locale(en)?/modeling-offer/:uniqueId',
        view: 'JobOffer/ModelingOffer',
        name: 'modelingOffer'
    },
    {
        path: '/:locale(en)?/model-selection/:uniqueId',
        view: 'JobOffer/ModelSelections',
        name: 'modelSelections',
        meta: { auth: true }
    },
    {
        path: '/:locale(en)?/client-register/:uniqueId',
        view: 'JobOffer/ClientRegister',
        name: 'clientRegister',
    },
    {
        path: '/:locale(en)?/offer/full-summery',
        view: 'JobOffer/FullSummery',
        name: 'offerFullSummery',
    },
    // email
    {
        path: '/:locale(en)?/email-templates',
        view: 'Email/EmailTemplates',
        name: 'emailTemplates',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/email-managment',
        view: 'Email/EmailManament',
        name: 'emailManagment',
        meta: { auth: true, types: ['manager'] }
    },
    // portfolio
    {
        path: '/:locale(en)?/portfolio',
        view: 'Portfolio/Portfolio',
        name: 'portfolio',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/add-portfolio',
        view: 'Portfolio/Add',
        name: 'addPortfolio',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/edit-portfolio/:id',
        view: 'Portfolio/Add',
        name: 'editPortfolio',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/portfolio-detail/:id',
        view: 'Portfolio/Detail',
        name: 'portfolioDetail',
        meta: { auth: true, types: ['manager'] }
    },
    // blog
    {
        path: '/:locale(en)?/blog',
        view: 'Blog/Blog',
        name: 'blog',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/add-blog',
        view: 'Blog/Add',
        name: 'addBlog',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/edit-blog/:id',
        view: 'Blog/Add',
        name: 'editBlog',
        meta: { auth: true, types: ['manager'] }
    },
    {
        path: '/:locale(en)?/blog-detail/:id',
        view: 'Blog/Detail',
        name: 'blogDetail',
        meta: { auth: true, types: ['manager'] }
    },
    // setting
    {
        path: '/:locale(en)?/setting',
        view: 'Setting/Setting',
        name: 'setting',
        meta: { auth: true, types: ['manager'] }
    },
]
