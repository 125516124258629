import BrandRepository from "@/abstraction/repository/brandRepository";
let brandRepository = new BrandRepository();
export default {
  async loadBrands({ state, commit }) {
    try {
      commit("SET_LOADING_BRAND", true);
      let data = { pagination: state.pagination, filters: state.filters };
      const resource = await brandRepository.index(data);
      commit("SET_BRANDS", resource.data);
      commit("SET_PAGINATION", resource.pagination);
    } catch (e) {
      return e;
    } finally {
      commit("SET_LOADING_BRAND", false);
    }
  },
  async storeBrand({ commit }, data) {
    const resource = await brandRepository.store(data);
    commit("ADD_BRAND", resource);
  },
  async updateBrand({ commit }, data) {
    const resource = await brandRepository.update(data.id, data);
    commit("UPDATE_BRAND", resource);
  },
  async deleteBrand({ commit }, brandId) {
    await brandRepository.delete(brandId);
    commit("DELETE_BRAND", brandId);
  },
};
