import EthnicRepository from "@/abstraction/repository/ethnicRepository";
let ethnicRepository = new EthnicRepository();
export default {
  async loadEthnics({ commit, state }) {

    if (state.ethnics.length) return;

    const response = await ethnicRepository.index();
    commit("SET_ETHNICS", response);
  },
};
